<script setup lang="ts">
import BaseUiModal from '@dns-modules/base-ui-modal/base-ui-modal.vue';

defineProps({
	hasReCaptcha: {type: Boolean, default: false}
});

const emit = defineEmits(['close']);

/** Шаблон формы. */
const FORM_TEMPLATE = formTemplates.FEEDBACK;

/** Заголовок письма при отправке формы. */
const FORM_TITLE = formTitles.FEEDBACK;

/** Закрытие модала */
const closeModal = () => {
	emit('close-modal');
}
</script>

<template>
	<div class="feedback-modal">
		<base-ui-modal
			title="Обратная связь"
			:is-popup="true"
			:classOfContainer="'common-modal-container feedback-modal-container'"
			@close="closeModal"
		>
			<div class="feedback-modal__form">
				<div class="feedback-modal__form-title">
					Если у остались вопросы пишите, мы обязательно ответим.
				</div>
				<base-form
					:hasReCaptcha="hasReCaptcha"
					:formTemplate="FORM_TEMPLATE"
					:title="FORM_TITLE"
					:isScenarioMain="true"
				/>
			</div>
		</base-ui-modal>
	</div>
</template>

<style lang="scss">
@import "assets/styles/variables/variables";
@import "assets/styles/dns-it-icons";


.feedback-modal {
	.feedback-modal-container {
		width:     600px;
		max-width: unset;

		@include respond-to(phone) {
			width:         100%;
			height:        100vh;
			border-radius: 0;
		}
	}

	&__form {
		@include respond-to(phone) {
			height: 586px;
		}
	}

	&__form {
		padding: 16px;

		.dns-form__inner {
			max-width: 100%;
		}
	}

	&__form-title {
		font-weight:    400;
		font-size:      18px;
		line-height:    150%;
		color:          #333;
		letter-spacing: -.4px;
		margin-bottom:  16px;
	}

	.dns-form {
		&__wrapper {
			margin: 0;
		}
	}
}
</style>
